import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Layout from "@components/Layout";
import Header from "@components/Header";
import SEO from "@components/SEO";
import PageHeading from "@components/PageHeading";
import LabeledIcon from "@components/LabeledIcon";

import FreedomIcon from "@images/icons/freedom.svg";
import MinimalismIcon from "@images/icons/minimalism.svg";
import QualityIcon from "@images/icons/quality.svg";
import TechIcon from "@images/icons/tech.svg";
import SocialDynamicsIcon from "@images/icons/social-dynamics.svg";
import StartupsIcon from "@images/icons/startups.svg";
import LearningIcon from "@images/icons/learning.svg";
import TravellingIcon from "@images/icons/travelling.svg";

const AboutPage = ({ location }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title={t("about.title")}
        description={t("about.description")}
        pathname={location.pathname}
      />
      <Header />
      <main>
        <div className="main-content-wrapper">
          <PageHeading>{t("about.heading")}</PageHeading>
          <ul className="about-values">
            <li>
              <LabeledIcon icon={FreedomIcon} label={t("about.freedom")} />
            </li>
            <li>
              <LabeledIcon
                icon={MinimalismIcon}
                label={t("about.minimalism")}
              />
            </li>
            <li>
              <LabeledIcon icon={QualityIcon} label={t("about.quality")} />
            </li>
            <li>
              <LabeledIcon icon={LearningIcon} label={t("about.learning")} />
            </li>
            <li>
              <LabeledIcon
                icon={TravellingIcon}
                label={t("about.travelling")}
              />
            </li>
            <li>
              <LabeledIcon
                icon={SocialDynamicsIcon}
                label={t("about.socialDynamics")}
              />
            </li>
            <li>
              <LabeledIcon icon={TechIcon} label={t("about.tech")} />
            </li>
            <li>
              <LabeledIcon icon={StartupsIcon} label={t("about.startups")} />
            </li>
          </ul>
        </div>
      </main>
    </Layout>
  );
};

AboutPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default AboutPage;
